@keyframes anim {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
.place {
  height: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(-60px);
}
.portfolio-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 10%;
  overflow: hidden;
  width: 92%;

  background: $color-1;
  --mask: conic-gradient(
      from 120deg at top,
      #0000,
      #000 1deg 119deg,
      #0000 120deg
    )
    50%/103.92px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  @media (max-width: 800px) {
    width: 100%;
    background: #0c0f0ae5;
  }
  @media (max-width: 600px) {
    min-height: 2910px;
  }
  @media (max-width: 1400px) {
    min-height: 1700px;
  }
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100%;
    color: white;
    text-shadow: 2px 0px 0px $color-3;
    font-size: 55px;
    margin-top: 2%;
    margin-bottom: 5%;
  }
  .folio-trigger {
    position: absolute;
    z-index: 0;
    top: 25%;
    height: 50px;
    width: 50px;
  }
  .portfolio {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0%;
    @media (max-width: 600px) {
      padding-top: 10%;
      justify-content: center;
    }

    article {
      display: flex;
      flex-direction: column;
      border: 2px inset $color-3;
      align-items: center;
      border-radius: 10%;
      box-shadow: 1px 1px 12px 10px rgb(0 0 0);
      backdrop-filter: blur(15px);
      box-shadow: inset 0px 0px 8px 0px white;
      overflow: hidden;
      width: 350px;
      height: 450px;
      margin: 10px 55px;
      cursor: pointer;
      &:hover {
        border: 2px inset rgb(0, 0, 0);
      }
      @media (max-width: 800px) {
        backdrop-filter: none;
      }
      @media (max-width: 600px) {
        margin: 5% 0%;
        width: 300px;
        height: 400px;
      }

      .pc-contain {
        z-index: 1;
        position: absolute;
        top: 5%;
        height: 290px;
        width: auto;
        filter: drop-shadow(5px 5px 3px #000000);
        @media (max-width: 600px) {
          height: 250px;
        }
      }
      .pc {
        position: absolute;
        z-index: 0;
        top: 6%;
        margin: 0%;
        height: 197px;
        width: 302px;
        object-fit: contain;
        @media (max-width: 600px) {
          height: 170px;
        }
      }
      #oh,
      #panthere {
        @media (max-width: 600px) {
          width: 250px;
        }
      }
      .tablet {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 3%;
        border: 10px inset black;
        border-radius: 15px;
        width: auto;
        height: 77px;
        object-fit: contain;
      }

      .mobile {
        position: absolute;
        top: 55%;
        z-index: 3;
        left: 38%;
        border-top: 8px solid rgb(255, 255, 255);
        border-bottom: 8px solid rgb(255, 255, 255);
        border-left: 2px solid rgb(255, 255, 255);
        border-right: 2px solid rgb(255, 255, 255);
        border-radius: 5px;
        width: auto;
        height: 57px;
        box-shadow: 3px 2px 6px 3px black;
      }
      h1 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        color: $color-3;
        text-shadow: 1px 0px 0px white;
        padding: 15%;
        margin-top: 95%;
        background: black;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        box-shadow: inset 0px 20px 20px 0px $color-3;
        @media (max-width: 600px) {
          margin-top: 105%;
          padding-bottom: 20px;
        }
      }
      .btn-container {
        position: absolute;
        bottom: 0%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background: $color-3;
        opacity: 93%;
        z-index: 5;
        width: 100%;
        height: 0%;
        clip-path: polygon(
          51% 5%,
          68% 0,
          84% 4%,
          100% 0,
          100% 100%,
          0 100%,
          0 0,
          17% 5%,
          35% 0
        );

        img {
          filter: drop-shadow(1px 5px 1px black);
          opacity: 0;
          height: 40px;
          width: auto;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          cursor: pointer;
          background: #000000;
          border-radius: 25px;
          color: $color-2;
          width: 100px;
          opacity: 0;
          text-decoration: none;
        }
      }
    }
  }
  .info {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1.5s ease;
    color: white;
    height: 0%;
    width: 100%;
    background: black;
    opacity: 0;
    z-index: -10;
  }
  .hi {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: 1.5s ease;
    height: 100%;
    width: 100%;
    color: white;
    background: black;
    opacity: 1;
    z-index: 100;
    h2 {
      color: $color-3;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
    }
    h3 {
      text-align: center;
      p {
        color: $color-4;
      }
    }
    p {
      text-align: center;
      font-size: 18px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    font-size: 40px;
    width: 100%;
    .moove {
      height: 100%;
      @media (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }

      p {
        color: rgb(255, 255, 255);
        text-shadow: 4px 1px 2px $color-3;
        filter: drop-shadow(4px 0px 1px black);
        margin: 8% 0px;
        font-size: 80px;
        font-weight: bold;
        @media (max-width: 600px) {
          font-size: 40px;
          margin: 0px 2px;
        }
      }
    }
    @media (max-width: 600px) {
      display: flex;
      flex-direction: row;
      height: 50px;
      z-index: 80;
      top: 0%;
      width: 100%;
    }
  }
}
