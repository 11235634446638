.menu {
  position: absolute;
  top: 1%;
  right: 0;
  height: 400px;
  width: 300px;
  cursor: pointer;
  @media (min-width: 600px) {
    display: none;
    z-index: 55;
  }
  .burger {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 2%;
    right: 6%;
    height: 35px;
    width: 35px;
    z-index: 50;
    .stick-1,
    .stick-2,
    .stick-3 {
      width: 35px;
      height: 4px;
      background: $color-3;
      border: 1px solid;
      border-radius: 10px;
    }
  
  }

  .burger-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 4;
    position: absolute;
    padding: 5%;
    border-radius: 15px;
    top: 18%;
    right: 0%;
    height: 250px;
    width: 125px;
    background: #141414;

    li {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: center;
      background: $color-2;
      border-radius: 15px;
      height: 100%;
      margin: 5px;
      border-top: 2px solid $color-3;
      border-bottom: 2px solid $color-3;
      width: 100%;
      box-shadow: inset 0px 0px 6px 2px rgb(0 0 0);
      transition: ease 0.5s;
      a {
        font-weight: bold;
        color: black;
        text-decoration: none;
      }
      &:hover {
        transform: scale(0.98);
      }
    }
  }
}
