html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0px;
  }

  h1 {
    margin: 0;
    padding: 0;
  }
  h2 {
    margin: 0;
    padding: 0;
  }

  ul {
    a {
      color: black;
      text-decoration: none;
    }
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
$color-1: #0c0f0afa;
$color-2: #f5f0e1;
$color-3: #f27438;
$color-4: #ffc13b;
