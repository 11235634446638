.nav-container {
  position: absolute;
  top: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 00px 0px 0px 0px;
  z-index: 1;
  @media (max-width: 600px) {
    display: none;
  }
  nav {
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    width: 92%;
    height: 80px;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;

      li {
        display: flex;
        width: 80px;
        border: 4px solid transparent;
        align-items: center;
        padding: 0% 2%;
        transition: ease-in-out 0.5s;
        z-index: 2;
        cursor: pointer;
        &:hover {
          border-bottom: 4px solid $color-3;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          color: white;
          font-size: 18px;
        }
      }
    }
  }
}
