@mixin aside {
  position: absolute;
  bottom: 0;
  right: 0;
  background: $color-2;
  border-radius: 15px 0px 0px 15px;
  height: 50px;
  border-top: 2px solid $color-3;
  border-bottom: 2px solid $color-3;
  width: 150px;
  z-index: 5;
  box-shadow: inset 0px 0px 6px 2px rgb(0 0 0);
}

.competence-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1100px) {
  }
  .containlAll {
    position: relative;
    display: flex;
    flex-direction: column;
    // background: $color-1;
    width: 92%;
    height: 100%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      padding: 50px 0px;
      width: 100%;
      background: $color-1;
      @media (max-width: 800px) {
        background: #0c0f0ae5;
      }

      @media (max-width: 400px) {
        font-size: 22px;
      }
    }
    .crop {
      width: 100%;
      height: 50px;
      background: $color-1;
      --mask: conic-gradient(
          from -60deg at bottom,
          #0000,
          #000 1deg 119deg,
          #0000 120deg
        )
        50%/103.92px 100%;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      @media (max-width: 800px) {
        background: #0c0f0ae5;
      }
    }
  }
  .dev-web {
    height: 10%;
    width: 100%;
    overflow: hidden;

    .section-dev {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .slide-contain {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .slider {
        position: relative;
        padding: 5px 0px;
        min-height: 90px;
        overflow: hidden;
        width: 100%;
        .slider-track {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          height: 100%;
          width: 100%;
          display: flex;
          animation: scroll 10s linear infinite;
          display: flex;
          @media (max-width: 800px) {
            flex-wrap: wrap;
          }
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 80px;
            backdrop-filter: blur(5px);
            border-radius: 50%;
            box-shadow: 0px 0px 4px 1px inset rgb(255, 255, 255);
            @media (max-width: 800px) {
              backdrop-filter: none;
            }
            img {
              height: 64px;
              width: 64px;
              object-fit: contain;
              filter: drop-shadow(2px 0px 1px $color-1);
            }
          }
        }
      }
    }
  }

  .dev-web-comp {
    overflow: hidden;
    //transform: translateY(-250px);
    rotate: 0deg;

    .separator {
      height: 50px;
      width: 3000px;
      transform: translateX(-50px);
      background: $color-1;
      --mask: conic-gradient(
          from -60deg at bottom,
          #0000,
          #000 1deg 119deg,
          #0000 120deg
        )
        50%/103.92px 100%;
      -webkit-mask: var(--mask);
      mask: var(--mask);
    }
    .crop2 {
      width: 3000px;
      transform: translateX(-55px);
      height: 50px;
      background: $color-1;
      border-bottom: 5px solid $color-1;
      --mask: conic-gradient(
          from 120deg at top,
          #0000,
          #000 1deg 119deg,
          #0000 120deg
        )
        50%/103.92px 100%;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      @media (max-width: 800px) {
        background: #0c0f0ae5;
      }
    }
    .section-dev {
      background: $color-1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 800px) {
        align-items: flex-start;
        justify-content: center;
        background: #0c0f0ae5;
      }
      @media (max-width: 600px) {
        width: 100%;
        align-items: center;
      }
      h2 {
        font-size: 35px;
        text-align: center;
        color: white;
        text-shadow: 2px 0px 0px $color-3;
        border-bottom: 2 px groove $color-3;
        @media (max-width: 800px) {
          width: 80%;
        }
      }
      ul {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        @media (max-width: 800px) {
          width: 80%;
        }
        li {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          justify-content: center;
          h3 {
            text-align: center;
            margin: 0;
            padding: 10px;
            color: $color-2;
          }
          p {
            text-align: center;
            // width: 100%;
            margin: 0;
            font-size: 18px;
            color: $color-4;
            padding-bottom: 25px;
            border-bottom: 2px solid $color-3;
          }
        }
      }
    }
  }
  .open-recommand {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    @media (max-width: 1300px) {
      margin-right: 15%;
    }
    .pc {
      width: 80%;
      height: 80%;
      border: 5px groove $color-3;
      box-shadow: 0px 0px 11px 20px rgb(0 0 0);
      border-radius: 25px;
      object-fit: contain;
      @media (max-width: 1300px) {
        margin-right: 15%;
      }
    }

    .recommandation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 10;
      background: #141414;
      border-radius: 15px;
      top: 50%;
      left: 0;
      height: auto;
      width: 50%;
      box-shadow: white 0px 0px 6px 2px;
      @media (max-width: 800px) {
        width: 100%;
        height: 80%;
      }

      h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2% 0%;
        width: 100%;
        height: 20%;
        color: white;
        border-bottom: 2px solid $color-3;
      }
      p {
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: center;
        font-size: 22px;
        color: white;
        padding: 0% 5%;

        @media (max-width: 600px) {
          padding-top: 300px;
          font-size: 17px;
          overflow: scroll;
        }
      }
    }
  }
  .btn_contain {
    position: absolute;
    bottom: 10%;
    right: 0;
    .recomand {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $color-2;
      border-radius: 15px 0px 0px 15px;
      height: 50px;
      border-top: 2px solid $color-3;
      border-bottom: 2px solid $color-3;
      width: 150px;
      z-index: 5;
      box-shadow: inset 0px 0px 6px 2px rgb(0 0 0);
      &-1 {
        @include aside();
        bottom: 60px;
        h4 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
        @media (max-width: 800px) {
          display: none;
        }
      }
      &-2 {
        @include aside();
        bottom: 120px;
        h4 {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
        @media (max-width: 800px) {
          display: none;
        }
      }
      @media (max-width: 800px) {
        display: none;
      }

      h4 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
