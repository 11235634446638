.contact-container {
  position: relative;
  overflow: hidden;
  transform: translateY(-150px);
  height: 120vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    transform: translateY(-75px);
  }
  @media (max-width: 600px) {
    transform: translateY(-140px);
  }

  .contact-trigger {
    position: absolute;
    z-index: 0;
    bottom: 30%;
    height: 50px;
    width: 50px;
  }
  .form-container {
    width: 92%;
    background: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    --mask: conic-gradient(
        from 120deg at top,
        #0000,
        #000 1deg 119deg,
        #0000 120deg
      )
      50%/103.92px 100%;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    @media (max-width: 600px) {
      background: transparent;
      border: none;
      --mask: none;
    }
    @media (max-width: 800px) {
      width: 100%;
    }

    form {
      overflow: hidden;
      position: relative;
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 25px;
      box-shadow: 0px 0px 15px 7px black;
      background: $color-1;
      width: 70%;
      height: 70%;
      color: $color-3;
      @media (max-width: 800px) {
        width: 80%;
      }
      @media (max-width: 500px) {
        height: 95%;
        width: 90%;
      }
      @media (max-width: 600px) {
        width: 100%;
        height: 80%;
      }
      .title {
        position: absolute;
        top: 0;
        height: 10%;
        background: #141414;
        width: 100%;
        border-bottom: 1px solid $color-3;
        h1 {
          height: 100%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;

        height: 50px;
        label {
          display: flex;
          justify-content: flex-start;
          width: 40%;
          font-size: 17px;
          font-weight: bold;
          text-shadow: 0px 0px 7px black;
        }
        input {
          width: 50%;
          padding-left: 2%;
          background: #141414;
          height: 30px;
          border-radius: 15px;
          color: white;
          font-size: 17px;
          box-shadow: inset 0px 0px 5px 0px #ffffff;
        }
      }
      .msg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;

        label {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          font-size: 17px;
          font-weight: bold;
          text-shadow: 0px 0px 7px black;
          margin-bottom: 15px;
        }
        textarea {
          width: 99.5%;
          height: 150px;
          display: flex;
          justify-content: flex-start;
          border-radius: 15px;
          color: white;
          font-size: 17px;
          box-shadow: inset 0px 0px 5px 0px #ffffff;
          background: #141414;
          @media (max-width: 600px) {
            height: 120px;
          }
        }
      }
    }
    .btn {
      position: absolute;
      bottom: 5%;
      left: 10%;
      height: 50px;
      width: 200px;
      color: white;
      font-size: 17px;
      box-shadow: inset 0px 0px 5px 0px #ffffff;
      background: #141414;
      border-radius: 15px;
      cursor: pointer;
      transition: 1.5s ease;
      border: none;
      &:hover {
        transform: scale(0.95);
        box-shadow: inset 0px 0px 15px 0px #000000;
        border: 1px solid black;
      }
      @media (max-width: 600px) {
        bottom: 0.5%;
      }
    }
  }
  .drop {
    top: 0;
    width: 100%;
    background: rgb(245, 240, 225);
    background: linear-gradient(
      0deg,
      rgba(245, 240, 225, 1) 1%,
      rgba(255, 110, 64, 1) 5%,
      rgba(0, 0, 0, 1) 20%
    );
    opacity: 90%;
    position: absolute;
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      81% 85%,
      65% 100%,
      48% 86%,
      31% 100%,
      15% 86%,
      0 100%
    );
  }
  .thank {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 5;
    background: #141414;
    border-radius: 15px;
    height: 500px;
    width: 500px;
    box-shadow: white 0px 0px 6px 2px;
    opacity: 0;
    @media (max-width: 600px) {
      width: 100%;
      height: 80%;
    }
    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 20%;
      color: white;
      border-bottom: 2px solid $color-3;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      text-align: center;
      color: white;
      padding: 0% 5%;
    }
  }
}
