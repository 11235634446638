.presentation-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
  .media {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 1%;
    left: 1%;
    img {
      cursor: pointer;
      filter: drop-shadow(0px 0px 2px #9b9393);
      height: 45px;
      margin: 5px 0px;
      @media (max-width: 800px) {
        margin: 5px 10px;
      }
    }
    @media (max-width: 800px) {
      flex-direction: row;
    }
  }
  .containAll {
    width: 92%;
    height: 100%;
    background: $color-1;
    @media (max-width: 800px) {
      width: 100%;
      background: #0c0f0ae5;
    }
  }
  .imgcontain {
    position: absolute;
    width: 92%;
    height: 100%;
    display: flex;
    align-content: flex-start;
    margin-top: 5%;
    @media (max-width: 800px) {
      align-items: flex-end;
      justify-content: center;
      width: 100%;
    }
    .moi {
      cursor: pointer;
      object-fit: cover;
      border-radius: 50%;
      height: 280px;
      filter: drop-shadow(0px 1px 2px $color-3);
      @media (max-width: 800px) {
        margin-bottom: 50px;
      }
    }
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 400px;
    height: 100%;

    @media (max-width: 600px) {
      padding-top: 150px;
    }
    h1 {
      color: rgb(255, 255, 255);
      text-shadow: 3px 0px 0px $color-3;
      filter: drop-shadow(1px 1px 0px rgb(255, 255, 255));
      font-size: 90px;
      padding: 0% 10%;
      @media (max-width: 1200px) {
        font-size: 65px;
      }
      @media (max-width: 600px) {
        font-size: 45px;
      }
      @media (max-width: 400px) {
        font-size: 35px;
      }
    }
    .name {
      font-size: 50px;
      margin-top: 0px;
      margin-bottom: 5px;
      color: rgb(255, 255, 255);
      text-shadow: 0px 0px 10px rgb(0, 0, 0);
      @media (max-width: 1000px) {
        font-size: 35px;
      }
      @media (max-width: 600px) {
        font-size: 25px;
      }
      @media (max-width: 400px) {
        margin-bottom: 20px;
      }
    }
    .me {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 900px;
      width: 600px;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
}
