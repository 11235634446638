.about-container {
  position: relative;
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(-62px);
  --mask: conic-gradient(
      from -60deg at bottom,
      #0000,
      #000 1deg 119deg,
      #0000 120deg
    )
    50%/103.92px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  @media (max-width: 1200px) {
    min-height: 1800px;
  }
  @media (max-width: 800px) {
    min-height: 1900px;
  }
  @media (max-width: 600px) {
    --mask: none;
  }

  section {
    width: 92%;
    height: 120vh;
    overflow: hidden;
    background: $color-1;
    @media (max-width: 800px) {
      width: 100%;
      background: #0c0f0ae5;
    }
    @media (max-width: 1200px) {
      height: 100%;
    }
  }
  .about-trigger {
    position: absolute;
    z-index: 0;
    top: 25%;
    height: 50px;
    width: 50px;
  }

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100%;
    color: white;
    text-shadow: 2px 0px 0px $color-3;
    font-size: 55px;
  }

  .contain {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    backdrop-filter: blur(3px);
    padding-bottom: 100px;
    @media (max-width: 800px) {
      backdrop-filter: none;
    }
    @media (max-width: 1200px) {
      margin-top: 100px;
      height: auto;
      flex-direction: column;
    }
    .futur {
      height: 600px;
      width: 600px;
      border-radius: 50%;
      box-shadow: 10px 7px 20px 13px black;
      margin-top: 100px;
      margin-bottom: 100px;
      @media (max-width: 600px) {
        height: auto;
        width: 90%;
      }
      article {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.699);
        border: 2px groove $color-3;
        overflow: hidden;
        @media (max-width: 600px) {
          border-radius: 5px;
          margin: 0;
          height: auto;
          width: 100%;
        }
        img {
          z-index: -1;
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        h3 {
          width: 100%;
          margin-bottom: 0;
          text-align: center;
          font-size: 25px;
          color: white;
        }
        p {
          display: flex;
          align-items: center;
          height: 100%;
          width: 80%;
          text-align: center;
          font-size: 20px;
          color: white;
          padding-bottom: 50px;
          @media (max-width: 600px) {
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
    .past {
      height: 600px;
      width: 600px;
      border-radius: 50%;
      box-shadow: 10px 7px 20px 13px black;
      margin-top: 100px;
      margin-bottom: 100px;
      @media (max-width: 600px) {
        height: auto;
        width: 90%;
      }
      article {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.699);
        border: 2px groove $color-3;
        overflow: hidden;
        @media (max-width: 600px) {
          border-radius: 5px;
          margin: 0;
          height: auto;
          width: 100%;
        }
        img {
          z-index: -1;
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        h3 {
          width: 100%;
          margin-bottom: 0;
          text-align: center;
          font-size: 25px;
          color: white;
        }
        p {
          display: flex;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 80%;
          font-size: 20px;
          color: white;
          padding-bottom: 50px;
          @media (max-width: 600px) {
            // text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
}
