.page-container {
  height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: block;
  background: $color-1;

  .home-container {
    background: blue;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .back {
    position: fixed;
    z-index: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover ;
  }
}
